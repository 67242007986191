<template>
  <div>
    <div v-if="splash_screen" id="splash-screen"></div>
    <router-view v-else/>
  </div>
</template>

<script>

import funexchlogo from "./assets/funexchlogo.png"
import { StatusBar, Style } from '@capacitor/status-bar';
import { App } from '@capacitor/app';

export default {
  name: 'App',

  data: () => ({
    funexchlogo,
    splash_screen: true,
  }),
  methods:{
    changeThemeMode(isDark){
      StatusBar.setBackgroundColor({
        color: isDark ?  "#000000" : "#000000"
      }).catch(err => {});
    },
    fetchBanner() {

      fetch("https://funexch.axenous.com/api/ads")
                .then(raw => raw.json())
                .then(response => {
                    console.log("banner", response)

                    localStorage.setItem("banner", JSON.stringify(response));

                }).catch(error => {
                    // this.loading = false;
                    console.log("error", error)
                })

    }
  },
  mounted() {

    if(localStorage.getItem('dark') == null) {
      localStorage.setItem('dark', 'true');
    }
    
    this.changeThemeMode((localStorage.getItem('dark') == 'true'));
    

    setTimeout(() => {
      this.splash_screen = false;
    }, 1000)

    App.addListener('backButton', ({ canGoBack }) => {
      if(!canGoBack){
        App.exitApp();
      } else {
        this.$router.back();
      }
    });

    this.fetchBanner();

  },
  watch: {
    '$vuetify.theme.dark': {
      handler: function (isDark) {
        this.changeThemeMode(isDark);
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">

@import "./scss/index.scss";

#splash-screen{
  background-image: url("./assets/funexchsplash.png");
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  background-size: cover;
  background-position: center;
}

.v-application {
  font-family: 'Manrope', sans-serif !important;
}

.theme--light.v-application {
  background: #EDF4F2 !important;
}

.v-btn {
  text-transform: none !important;
}
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}

.v-bottom-navigation {
  box-shadow: 5px 2px 15px rgb(0 0 0 / 25%) !important;
  &> button{
    height: 100% !important;
  }
}

.v-tab:before, .v-btn:before {
  background-color: transparent !important;
}
.v-main{
  
  .v-main__wrap{
    // border-top-left-radius: 16px;
    // border-top-right-radius: 16px;
    // background-color: #EDF4F2;
  }
}
.v-item-group.v-bottom-navigation .v-btn .v-btn__content{
  text-transform: uppercase;
}

.v-tabs-bar{
  height: 39px !important;
}

.theme--light.v-tabs > .v-tabs-bar
{
  background-image: linear-gradient(180deg, #04601a , #00a826) !important;
}

.theme--dark.v-tabs > .v-tabs-bar
{
  background-image: linear-gradient(180deg, #04601a , #00a826) !important;
}



.v-tab {
  min-width: min-content !important;
  padding: 0px !important;
  margin: 0px 12px !important;
  font-weight: 600 !important;
}

.v-tab--active
{
  color: #ffffff   !important;
  font-weight: 900 !important;
}

.content {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 100%;
}

.v-tabs-items{
  background-color: transparent !important;
}

</style>