<template>
    <div>
        <div class="pt-6 px-5">

            <div v-if="loading && !dont_show_loading" class="mt-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
                <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
            </div>

            <EmptyState v-if="!loading && data != null && data.length == 0"/>

            <!-- <div>
                {{ data }}
            </div> -->

            <v-card v-if="data && data.batsman" class="mb-4" outlined>
                <v-card-text>
                    <div>
                        <table class="live-score-table" style="width: 100%;">
                            <tr>
                                <th class="text-left pe-3" style="font-weight: 600;">Batsman</th>
                                <th class="text-right pe-2" style="font-weight: 600; width: 1%;">R(B)</th>
                                <th class="text-left px-2" style="font-weight: 600; width: 1%;">4s</th>
                                <th class="text-left px-2" style="font-weight: 600; width: 1%;">6s</th>
                                <th class="text-right" style="font-weight: 600; width: 1%;">SR</th>
                            </tr>
                            <tr v-for="(batsman, i) in data.batsman" :key="i">
                                <td class="pe-3 d-flex align-center text-type-1" style="font-weight: 700;">
                                    {{ getShortName(batsman.name) }}
                                    <CricketBat v-if="batsman.name.includes('*')" class="ms-1"/>
                                </td>
                                <td class="text-right text-type-1 pe-2" style="font-weight: 700;">{{ batsman.run }}({{ batsman.ball }})</td>
                                <td class="text-center">{{ batsman.fours }}</td>
                                <td class="text-center">{{ batsman.sixes }}</td>
                                <td class="text-right">{{ batsman.strike_rate }}</td>
                            </tr>
                        </table>
                        <div class="d-flex mt-2">
                            <div v-if="data.partnership && data.partnership.run && data.partnership.ball" class="flex-grow-1">P'SHIP: {{ data.partnership.run }}({{ data.partnership.ball }})</div>
                            <div v-if="data.lastwicket && data.lastwicket.player" class="flex-grow-1 text-right">LAST WKT: {{  getShortName(data.lastwicket.player) }} {{  data.lastwicket.run }}({{  data.lastwicket.ball }})</div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>

            <v-card v-if="data && data.bolwer" outlined>
                <v-card-text>
                    <div>
                        <table style="width: 100%;">
                            <tr>
                                <th class="text-left" style="font-weight: 600;">Bowler</th>
                                <th class="text-left" style="font-weight: 600;">W-R</th>
                                <th class="text-left" style="font-weight: 600;">Over</th>
                                <th class="text-left" style="font-weight: 600;">Econ</th>
                            </tr>
                            <tr>
                                <td class="text-type-1" style="font-weight: 700;">{{ getShortName(data.bolwer.name) }}</td>
                                <td class="text-type-1" style="font-weight: 700;">{{ data.bolwer.wicket }}({{ data.bolwer.run }})</td>
                                <td>{{ data.bolwer.over }}</td>
                                <td>{{ data.bolwer.economy }}</td>
                            </tr>
                        </table>
                    </div>
                </v-card-text>
            </v-card>

            <div class="d-flex align-center mt-3" style="width: 100%;">
                <div>
                    <v-btn icon :disabled="overs_tab == 0" @click="overs_tab--">
                        <ArrowLeft/>
                    </v-btn>
                </div>
                <div class="flex-grow-1">
                    <v-tabs-items v-model="overs_tab" class="overhistory-tabs" background-color="transparent">
                        <v-tab-item v-for="(over, i) in last4overs" :key="i">
                            <div class="d-flex align-center">
                                <span class="mx-1" style="font-weight: 800; text-transform: uppercase; font-size: 19px;">
                                    Over {{ over.over }}
                                </span>
                                <span class="ball mx-1" v-for="(ball, k) in reverseOrder(over.balls)" :key="k" :style="getBallStyle(ball)">
                                    {{ ball }}
                                </span>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <div>
                    <v-btn icon @click="overs_tab++" :disabled="overs_tab == (last4overs.length - 1)">
                        <ArrowRight/>
                    </v-btn>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import EmptyState from "./../EmptyState.vue";
import CricketBat from "../../assets/svg/CricketBat.vue";
import ArrowLeft from "./../../assets/svg/ArrowLeft.vue";
import ArrowRight from "./../../assets/svg/ArrowRight.vue";

export default {
    props: [
        'data', 'loading'
    ],
    components: {
        EmptyState, CricketBat, ArrowLeft, ArrowRight
    },
    data() {
        return {
            match_id: this.$route.params.match_id,
            overs_tab: 0,
            dont_show_loading: false,
        }
    },
    methods: {
        reverseOrder(arr) {
            let a = [...arr];
            return a.reverse();
        }
    },
    mounted() {},
    computed: {
        last4overs() {
            return this.data?.last4overs?.reverse() ?? [];
        }   
    },
    watch:{
        loading(){
            if(this.loading) {
                this.dont_show_loading = true;
            }
        }
    }
}
</script>
<style lang="scss">
.overhistory-tabs {
    .v-tabs-slider-wrapper {
        display: none;
    }
    .v-tab--active {
        color: #00a826  !important;
        font-weight: 600 !important;
    }
    .v-ripple__container {
        display: none;
    }
    &.theme--dark {
        .v-tab--active {
        color: rgba(255, 255, 255, 0.6) !important;
            font-weight: 600 !important;
        }
    }
    .v-slide-group__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
}

// .live-score-table {
//     tr th {
//         font-weight: 600 !important;
//     }
// }

</style>