<template>

    <div class="content bg-type-2">

        <div v-if="loading" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <div class="pt-4">
            <v-lazy-list :items="list" @on-load-more="loadMore">
                <template #item="{value}">
                    <div>
                        <div class="pa-3" @click="$router.push({ name: 'series-view', params: { 'series_id':  value.item.series_id }, query:{ name: value.item.series } })">
                            <div class="d-flex">
                                <div class="flex-grow-1">{{ value.item.series }}</div>
                                <ArrowBack width="18px" height="18px" class="primary--text" style="transform: rotate(180deg);"/>
                            </div>
                        </div>
                        <v-divider/>
                    </div>
                </template>
            </v-lazy-list>
        </div>
        
        

        <!-- <table class="series-table pt-4" style="width: 100%;">
            <tr v-for="item in series" :key="item.series_id">
                <td @click="$router.push({ name: 'series-view', params: { 'series_id':  item.series_id }, query:{ name: item.series } })">
                    <div class="d-flex">
                        <div class="flex-grow-1">{{ item.series }}</div>
                        <ArrowBack width="18px" height="18px" class="primary--text" style="transform: rotate(180deg);"/>
                    </div>
                </td>
            </tr>
        </table> -->

    </div>

</template>
<script>
import ArrowBack from "./../../assets/svg/ArrowBack.vue"
export default {
    name: 'SeriesTab',
    components:{
        ArrowBack
    },
    data() {
        return {
            loading: true,
            isStandingButton: false,
            list: [],
            series: []
        }
    },
    methods: {
        loadMore() {
            if(this.list.length >= this.series.length) return;
            let x = this.series.slice(this.list.length, this.list.length + 5);
            x.forEach(y => {
                this.list.push(y);
            });
        },
        fetchSeries() {
            
            this.loading = true;

            api("seriesList")
                .then(response => {
                    console.log("response", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.series = response.data;
                    }
                }).catch(error => {
                    this.loading = false;
                    console.log("error", error)
                })

        }
    },
    watch: {
        series() {
            if(this.series && this.series.length > 0) {
                this.list = Object.assign([], this.series.slice(0, 20));    
            }
        }
    },
    mounted(){
        this.fetchSeries();
    }
}
</script>
<style lang="scss">
    .series-table {
        tr {
            td {
                border-bottom: 1px solid var(--grey);
                padding: 14px 22px;
            }
        }
    }
</style>