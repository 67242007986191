<template>
    <div>
        <v-list lines="two">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Dark Mode</v-list-item-title>
                    <v-list-item-subtitle>
                        Enable dark mode in the whole app
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-switch v-model="dark"></v-switch>
                </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item @click="openAboutUs">
                <v-list-item-content>
                    <v-list-item-title>About Us</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item @click="$router.push({ name: 'privacy_policy' })">
                <v-list-item-content>
                    <v-list-item-title>Privacy Policy</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item @click="$router.push({ name: 'terms_and_conditions' })">
                <v-list-item-content>
                    <v-list-item-title>Terms of Use</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
        </v-list>
    </div>
</template>
<script>
import { Browser } from '@capacitor/browser';

export default {
    name: 'More',
    methods: {
    //   changeMode() { 
    //     this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    //   }
    },
    data(){
        return {
            dark: (localStorage.getItem('dark') == 'true')
        }
    },
    methods: {
        openAboutUs(){
            Browser.open({ url: 'https://Funexch.com/' });
        }
    },
    mounted() {
        
    },
    watch:{
        dark() {
            this.$vuetify.theme.dark = this.dark;
            localStorage.setItem('dark', this.dark);
        }
    }
}
</script>