<template>
    <div class="content">

        <v-container style="height: 100%;">

            <v-tabs-items v-model="tab">

                <v-tab-item>

                    <v-chip-group class="matches-chips mb-3" :value="0">
                        <v-chip v-for="(item, i) in series" :key="i" 
                            class="mr-3"
                            :style="(i == 0) ? 'margin-left: 12px;' : ''"
                            :ripple="false"
                            @click="openSeries(item)">
                            {{ item.name }}
                        </v-chip>
                    </v-chip-group>

                    

                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto border mb-4"
                        max-height="300"
                        type="article"
                        v-for="i in 2" :key="i">
                    </v-skeleton-loader>

                    <EmptyState v-if="!loading && home_data != null && home_data.length == 0"/>

                    <!-- <MatchCard
                        class="mb-4"
                        :is_live="!(match.match_status == 'Upcoming')"
                        :is_upcoming="match.match_status == 'Upcoming'"
                        v-for="match in home_data" :key="match.match_id"
                        :data="match">
                    </MatchCard> -->

                    <MatchCard
                        v-if="liveMatches" class="mb-4"
                        :is_live="true"
                        v-for="(match) in liveMatches" :key="match.match_id"
                        :data="match"/>

                    <MatchCard
                        v-if="recentMatches" class="mb-4"
                        :is_recent="true"
                        v-for="(match) in recentMatches" :key="match.match_id"
                        :data="match"/>

                    <MatchCard
                        v-if="upcomingMatches" class="mb-4"
                        :is_upcoming="true"
                        v-for="(match) in upcomingMatches" :key="match.match_id"
                        :data="match"/>
                    
                    <!-- <v-card outlined>
                        <v-card-text>
                        <div style="color: #9FACAD;">
                            <span class="font-weight-bold text-type-3">Result</span> - Match 9 - Fairback Global Womens T20
                        </div>

                        <table class="font-weight-bold mt-2" style="width: 100%">
                            <tr>
                            <td class="d-flex align-center text-type-1">
                                <div class="mr-2 pa-1">
                                    <v-img
                                        class="white rounded-circle"
                                        width="24"
                                        aspect-ratio="1"
                                        :src="team1"
                                        rounded/>
                                </div>
                                SPI-W
                            </td>
                            <td class="text-right text-type-1">151/4 (15.4 ov)</td>
                            </tr>
                            <tr>
                            <td class="d-flex align-center text-type-1">
                                <div class="mr-2 pa-1">
                                    <v-img
                                        class="white rounded-circle"
                                        width="24"
                                        aspect-ratio="1"
                                        :src="team2"/>
                                </div>
                                TOR-W
                            </td>
                            <td class="text-right text-type-2">151/4 (20 ov)</td>
                            </tr>
                        </table>

                        <div class="mt-2 mb-4 text-type-5">SPI-W won by 6 wickets</div>

                        <div class="d-flex px-6" style="width: 100%;">
                            <v-btn 
                                class="font-weight-bold mr-10 btn-1" 
                                style="flex:1;" 
                                color="primary" 
                                text rounded
                                :ripple="false"
                                @click="$router.push({ name: 'match-info' })">Fixtures</v-btn>
                            <v-btn 
                                class="font-weight-bold btn-1"
                                style="flex:1;"
                                color="primary"
                                text rounded
                                :ripple="false">
                                Standings
                                </v-btn>
                        </div>

                        </v-card-text>
                    </v-card> -->

                    <!-- <v-card class="mt-6" outlined>
                        <v-card-text>
                        <div style="color: #9FACAD;">
                            <span class="font-weight-bold text-type-4">Upcoming</span> - Match 17 - Chennai
                        </div>

                        <table class="font-weight-bold mt-2" style="width: 100%">
                            <tr>
                            <td class="d-flex align-center text-type-1">
                                <div class="mr-2 pa-1">
                                <v-img width="24" aspect-ratio="1" :src="team3"></v-img>
                                </div>
                                Chennai Super King
                            </td>
                            </tr>
                            <tr>
                            <td class="d-flex align-center text-type-1">
                                <div class="mr-2 pa-1">
                                <v-img width="24" aspect-ratio="1" :src="team4"></v-img>
                                </div>
                                Royal Challenger Bangalore
                            </td>
                            </tr>
                        </table>

                        <div class="mt-2 mb-4 text-type-5">Match starts on 12 Apr 2023 at 07:30 PM</div>

                        <div class="d-flex px-6" style="width: 100%;">
                            
                            <v-btn 
                                class="font-weight-bold mr-10 btn-1" 
                                style="flex:1;" 
                                color="primary" text rounded
                                :ripple="false">
                                Fixtures
                            </v-btn>
                            
                            <v-btn 
                                class="font-weight-bold btn-1"
                                style="flex:1;"
                                color="primary"
                                text rounded
                                :ripple="false">
                                Standings
                            </v-btn>
                        </div>

                        </v-card-text>
                    </v-card> -->

                    <!-- <img width="100%" :src="banner"> -->

                    <!-- <v-img 
                        v-if="current_banner"
                        class="img-bg mt-4 mb-6"
                        :src="current_banner.image_url_full"
                        :aspect-ratio="current_banner.aspect_ratio"
                        @click="openLink(current_banner.target_url)">
                    </v-img> -->

                    <div v-if="upcomingMatches && upcomingMatches.length > 0" class="d-flex mb-2 px-2">
                        <div class="text-h6">Upcoming Matches</div>
                        <v-spacer/>
                        <v-btn class="font-weight-bold" color="primary" small text @click="tab = 2">View All</v-btn>
                    </div>

                    <v-card class="overflow-hidden" outlined>
                        <v-card-text class="pa-0">
                            <v-simple-table v-if="upcomingMatches">
                                <template v-slot:default>
                                <tbody>
                                    <tr v-for="match in upcomingMatches" :key="match.match_id" @click="openMatch(match.match_id)">
                                        <td>
                                            <div class="d-flex align-center font-weight-bold">
                                            <div class="mr-2 pa-1">
                                                <v-img class="img-bg rounded-circle" width="30" aspect-ratio="1" :src="match.team_a_img"></v-img>
                                            </div>
                                            {{ match.team_a_short }}
                                            </div>
                                        </td>
                                        <td class="text-center grey--text text--darken-1">
                                            {{ match.match_date }}
                                        </td>
                                        <td class="text-right">
                                            <div class="d-flex align-center justify-end font-weight-bold">
                                                {{ match.team_b_short }}
                                                <div class="ml-2 pa-1">
                                                    <v-img class="img-bg rounded-circle" width="30" aspect-ratio="1" :src="match.team_b_img"></v-img>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td>
                                            <div class="d-flex align-center font-weight-bold">
                                            <div class="mr-2 pa-1">
                                                <v-img width="30" aspect-ratio="1" :src="team4"></v-img>
                                            </div>
                                            RCB
                                            </div>
                                        </td>
                                        <td class="text-center grey--text text--darken-1">11 APR 2023</td>
                                        <td class="text-right">
                                            <div class="d-flex align-center justify-end font-weight-bold">
                                            GT
                                            <div class="ml-2 pa-1">
                                                <v-img width="30" aspect-ratio="1" :src="team7"></v-img>
                                            </div>
                                            </div>
                                            
                                        </td>
                                    </tr> -->
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <LiveTab :list="liveMatches"/>
                </v-tab-item>
                <v-tab-item>
                    <UpcomingTab :list="upcomingMatches"/>
                </v-tab-item>
                <v-tab-item>
                    <ResultTab/>
                </v-tab-item>
            </v-tabs-items>

            

        </v-container>

    </div>
</template>
<script>

import team1 from "../../assets/team-1.png"
import team2 from "../../assets/team-2.png"
import team3 from "../../assets/team-3.png"
import team4 from "../../assets/team-4.png"
import team5 from "../../assets/team-5.png"
import team6 from "../../assets/team-6.png"
import team7 from "../../assets/team-7.png"
// import banner from "../../assets/banner.png"

import LiveTab from "./HomeTab/LiveTab.vue"
import UpcomingTab from "./HomeTab/UpcomingTab.vue"
import ResultTab from "./HomeTab/ResultTab.vue"

import MatchCard from "./../MatchCard.vue";

import { Browser } from '@capacitor/browser';

import EmptyState from "./../EmptyState.vue";

export default {
    props: [ 'value' ],
    components: {
        LiveTab, 
        UpcomingTab,
        MatchCard,
        ResultTab,
        EmptyState
    },
    data() {
      return {
        chip: 0,
        team1, team2, team3, team4, team5, team6, team7,
        // banner,
        chips: [
          'Matches (12)',
          'Fairbreak Global Womens T20',
          'ECS Cyprus T10', 'IND vs AUS'
        ],

        upcomingMatches: null,
        liveMatches: null,
        recentMatches: null,

        home_data: null,

        series: [{ name: 'Matches' }],

        // section_ads: [],

        loading: false,

      }
    },
    methods:{
        openMatch(match_id) {
            this.$router.push({ name: 'match-info',
                params: {
                    match_id: match_id, 
                },
                query: {
                    match_status: "upcoming"
                }
            });
        },
        fetchHomeData() {
            this.loading = true;
            api("home")
                .then(response => {
                    console.log("home", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.home_data = response.data;
                    }else{
                        this.home_data = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.home_data = [];
                    console.log("error", error)
                })
        },
        openSeries(series) {
            if(series.series_id) {
                this.$router.push({ 
                    name: 'series-view',
                    params: { 
                        'series_id':  series.series_id 
                    },
                    query:{
                        'name':  series.name 
                    }
                })
            }
        },
        getRandom(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        openLink(link) {
            if(link) {
                Browser.open({ url: link });
            }
        }
    },
    mounted() {

        this.fetchHomeData();

        // this.fetchUpcomingMatches();
        // this.fetchLiveMatches();
    },
    computed:{
        tab:{
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
        banner() {
            return JSON.parse(localStorage.getItem('banner'));
        },
        section_ads() {
            if(this.banner) {
                let section = this.banner.find(x => x.name == "Section A");
                if(section && section.ads) {
                    return section;
                }
            }
        },
        current_banner() {
            if(this.section_ads && this.section_ads.ads && this.section_ads.ads.length > 0) {
                let rnd = this.getRandom(1, this.section_ads.ads.length);
                let rndBanner = this.section_ads.ads[rnd - 1];
                console.log("rndBanner", rndBanner)

                rndBanner['aspect_ratio'] = this.section_ads.height / this.section_ads.width;

                return rndBanner;
            }
        }
    },
    watch: {
        home_data() {
            if(this.home_data && this.home_data.length > 0) {
                
                this.series = [{
                    name: 'Matches'
                }];

                this.upcomingMatches = [];
                this.liveMatches = [];

                this.recentMatches = [];

                this.home_data.forEach(match => {
                    
                    let x = this.series.find(k => k.series_id == match.series_id);

                    if(x == undefined) {
                        this.series.push({
                            name: match.series,
                            series_id: match.series_id
                        });
                    }

                    if(match.match_status == "Upcoming") {
                        this.upcomingMatches.push(match);
                    } else if(match.match_status == "Finished") {
                        this.recentMatches.push(match);
                    }else{
                        this.liveMatches.push(match);
                    }

                })

            }
        }
    }
}
</script>